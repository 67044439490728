import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Header = ({ siteTitle, className }) => (
  <header className={className}>
    <div>
      <h1>
        <Link to="/">{siteTitle}</Link>
      </h1>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  className: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
  className: ``,
};

export default Header;
